<template>
    <base-dialog width="600" :title="dynamicTitles.card" :is-open.sync="dialog">
        <v-card-text class="pt-7 px-2">
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <v-col cols="12">
                            <base-input-label required hint="max. 5mb">{{
                                dynamicTitles.image
                            }}</base-input-label>

                            <div
                                class="d-flex justify-space-between align-center"
                            >
                                <v-file-input
                                    solo
                                    rounded
                                    :prepend-icon="null"
                                    show-size
                                    small-chips
                                    color="secondary"
                                    clearable
                                    flat
                                    hide-details
                                    accept="image/*"
                                    class="rounded-lg"
                                    id="image"
                                    v-model="form.image"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip color="secondary" small label>
                                            {{ text }}
                                        </v-chip>
                                    </template>
                                </v-file-input>
                                <div class="mx-2"></div>
                                <v-btn
                                    class="caption font-weight-bold text-capitalize"
                                    small
                                    depressed
                                    rounded
                                    dark
                                    color="secondary"
                                    @click="openFileInput('image')"
                                    >Select File</v-btn
                                >
                            </div>

                            <v-menu
                                max-width="20rem"
                                top
                                v-if="operation.editMode && form.existing_image"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="pt-2">
                                        <span
                                            class="caption text-decoration-underline secondary--text c-pointer"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            View existing image
                                        </span>
                                    </div>
                                </template>
                                <v-card width="20rem">
                                    <v-img
                                        :src="form.existing_image"
                                        width="100%"
                                        height="auto"
                                    ></v-img>
                                </v-card>
                            </v-menu>
                        </v-col>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions class="pa-0">
            <v-container fluid>
                <v-row align="center" justify="center" class="py-1 px-2">
                    <v-col md="2" cols="12" class="px-2">
                        <v-btn block depressed dark color="#389877" rounded
                            >Save</v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </base-dialog>
</template>

<script>
import BaseDialog from "@/components/Base/BaseDialog";
import BaseInputLabel from "@/components/Base/BaseInputLabel";

const defaultForm = {
    image: null,
    existing_image: null,
};

export default {
    name: "banner-form-dialog",

    components: { BaseInputLabel, BaseDialog },

    props: {
        show: { type: Boolean, default: false, required: true },
        action: { type: String, default: "", required: true },
        data: { type: [Object, null], required: false },
    },

    data() {
        return {
            dialog: this.show,
            dataLocal: Object.assign({}, this.data),
            form: Object.assign({}, defaultForm),
        };
    },

    computed: {
        operation() {
            return {
                createMode: this.action === "add",
                editMode: this.action === "edit",
            };
        },

        dynamicTitles() {
            let title = {
                card: null,
                image: null,
            };
            if (this.operation.createMode) {
                title = Object.assign(
                    {},
                    {
                        card: "Add Banner",
                        image: "Add Image",
                    }
                );
            } else if (this.operation.editMode) {
                title = Object.assign(
                    {},
                    {
                        card: "Edit Banner",
                        image: "Replace Existing Image",
                    }
                );
            }

            return title;
        },
    },

    watch: {
        show(to) {
            this.dialog = to;
        },

        dialog(value) {
            if (!value && this.operation.createMode) {
                this.$emit("close");
            } else if (!value && this.operation.editMode) {
                this.$emit("close");
                // this.resetForm();
            }
        },

        data(value) {
            if (
                this.action === "edit" &&
                this.data &&
                Object.keys(value).length > 0
            ) {
                console.log(value);
            }
        },
    },

    methods: {
        openFileInput(elementId) {
            this.$nextTick(() => {
                document.getElementById(elementId).click();
            });
        },
    },
};
</script>
