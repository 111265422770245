<template>
    <v-container
        id="users"
        fluid
        class="fill-height flex-column flex-nowrap align-start"
    >
        <v-container fluid class="pt-5 px-5 mb-10">
            <v-row justify="space-between">
                <v-col cols="12" md="7" class="d-flex">
                    <v-toolbar-title
                        class="headline font-weight-bold accent--text"
                        >Settings</v-toolbar-title
                    >
                    <v-select
                        v-model="selectedSettingPage"
                        background-color="transparent"
                        item-text="text"
                        item-value="value"
                        :menu-props="{ top: false, offsetY: true }"
                        class="ma-0 pa-0 ml-10 ministry-pages"
                        :items="settingItems"
                        @change="changeView"
                        color="primaryTextColor"
                        :style="`max-width: 200px; font-size: 20px; font-weight: 800;`"
                    ></v-select>
                </v-col>

                <v-col
                    md="5"
                    cols="12"
                    class="d-inline-flex flex-row justify-space-between"
                >
                </v-col>
            </v-row>
        </v-container>

        <v-container
            fluid
            class="flex-grow-1 fill-height align-start justify-center px-5"
        >
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <span class="font-weight-bold success--text"
                                >Home</span
                            >
                        </v-col>
                        <v-col cols="12" md="4" xl="3">
                            <banner-preview-card
                                :data="sampleData"
                                @onOpenForm="openBannerFormDialog"
                            ></banner-preview-card>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <span class="font-weight-bold success--text"
                                >Watch</span
                            >
                        </v-col>
                        <v-col cols="12" md="4" xl="3">
                            <banner-preview-card
                                :data="null"
                                @onOpenForm="openBannerFormDialog"
                            ></banner-preview-card>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <span class="font-weight-bold success--text"
                                >Learn</span
                            >
                        </v-col>
                        <v-col cols="12" md="4" xl="3">
                            <banner-preview-card
                                :data="null"
                                @onOpenForm="openBannerFormDialog"
                            ></banner-preview-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>

        <banner-form-dialog
            :show.sync="bannerForm.show"
            :action="bannerForm.action"
            :data="bannerForm.data"
            @close="bannerForm = { ...bannerForm, show: false, data: null }"
            v-if="bannerForm.show"
        ></banner-form-dialog>
    </v-container>
</template>

<script>
import BannerPreviewCard from "@/components/MainView/Settings/Banners/components/BannerPreviewCard";
import BannerFormDialog from "@/components/MainView/Settings/Banners/components/BannerFormDialog";

export default {
    name: "settings-banner",

    components: { BannerFormDialog, BannerPreviewCard },

    data() {
        return {
            selectedSettingPage: "/settings/banner",
            settingItems: [
                {
                    text: "Access Controls",
                    value: "/settings/access-control",
                },
                {
                    text: "Banners",
                    value: "/settings/banner",
                },
            ],
            bannerForm: { show: false, action: "", data: null, type: null },
            sampleData: {
                id: 15,
                title: "Life Journey 4",
                sub_title: "Life's Leadership Advance",
                description:
                    "These are learning resources to help you spur on fellow disciples of Christ to lead others towards spiritual maturity.",
                thumbnail:
                    "https://gcf-storage.s3.ap-southeast-1.amazonaws.com/staging/learns/sermon_study_guides/thumbnails/z7GqKuJ1eCpXNZ23ZM9iNVlKIUiqvI6hi2HGk7oA.png",
                featured_on_front_page: 0,
                created_at: "2022-09-01T11:34:01.000000Z",
                learn_collection: {
                    id: 1,
                    name: "Life Journey",
                    created_at: "2022-07-26 08:48:12",
                    updated_at: "2022-07-26 08:48:12",
                    deleted_at: null,
                },
                life_journey_subjects: [
                    {
                        id: 17,
                        title: "Growth Group Leader's Training 1",
                        description:
                            "These resources may be helpful for mature believers who want to lead a small group of people in their journey towards spiritual maturity.",
                        thumbnail: null,
                        created_at: "2022-09-01T11:34:01.000000Z",
                        total_pages: "80",
                        hours_to_read: "4",
                        attachments: [
                            {
                                id: 72,
                                attachmentable_type:
                                    "App\\Models\\LifeJourneySubject",
                                attachmentable_id: 17,
                                thumbnail: null,
                                name: "Biblical Disciplemaking",
                                description: null,
                                file: "https://gcf-storage.s3.ap-southeast-1.amazonaws.com/staging/learns/life_journeys/pdfs/SRmfM5o1I8GJWiVoN5wA0mX14xgcRmupgPj2ABtm.pdf",
                                google_slides_url: null,
                                pdf_url: null,
                                pages: 24,
                                hour_to_read: 1,
                                created_at: "2022-09-01 11:34:01",
                                updated_at: "2022-09-01 11:34:01",
                                deleted_at: null,
                            },
                            {
                                id: 73,
                                attachmentable_type:
                                    "App\\Models\\LifeJourneySubject",
                                attachmentable_id: 17,
                                thumbnail: null,
                                name: "Bible Study Methods",
                                description: null,
                                file: "https://gcf-storage.s3.ap-southeast-1.amazonaws.com/staging/learns/life_journeys/pdfs/LaB69TNpwHfnZKj5MOIGXRhkr1tTqu0nlgSEIogr.pdf",
                                google_slides_url: null,
                                pdf_url: null,
                                pages: 23,
                                hour_to_read: 1,
                                created_at: "2022-09-01 11:37:06",
                                updated_at: "2022-09-01 11:37:06",
                                deleted_at: null,
                            },
                            {
                                id: 74,
                                attachmentable_type:
                                    "App\\Models\\LifeJourneySubject",
                                attachmentable_id: 17,
                                thumbnail: null,
                                name: "Missional GGs",
                                description: null,
                                file: "https://gcf-storage.s3.ap-southeast-1.amazonaws.com/staging/learns/life_journeys/pdfs/wBI3bjXD1En0focouNnfGHIHD9I6O1spB0tj361Y.pdf",
                                google_slides_url: null,
                                pdf_url: null,
                                pages: 15,
                                hour_to_read: 1,
                                created_at: "2022-09-01 11:37:06",
                                updated_at: "2022-09-01 11:37:06",
                                deleted_at: null,
                            },
                            {
                                id: 75,
                                attachmentable_type:
                                    "App\\Models\\LifeJourneySubject",
                                attachmentable_id: 17,
                                thumbnail: null,
                                name: "How to Lead Growth Groups",
                                description: null,
                                file: "https://gcf-storage.s3.ap-southeast-1.amazonaws.com/staging/learns/life_journeys/pdfs/AAcMTcPk8dVlfuT4zMuLrU3AWtbN8QRazgGA9A07.pdf",
                                google_slides_url: null,
                                pdf_url: null,
                                pages: 18,
                                hour_to_read: 1,
                                created_at: "2022-09-01 11:37:06",
                                updated_at: "2022-09-01 11:37:06",
                                deleted_at: null,
                            },
                        ],
                    },
                    {
                        id: 18,
                        title: "Growth Group Leader's Training 3",
                        description:
                            "These resources may be helpful for mature believers who want to learn how to take care or shepherd a small group of people and meet their needs.",
                        thumbnail: null,
                        created_at: "2022-09-01T11:39:19.000000Z",
                        total_pages: "18",
                        hours_to_read: "2",
                        attachments: [
                            {
                                id: 76,
                                attachmentable_type:
                                    "App\\Models\\LifeJourneySubject",
                                attachmentable_id: 18,
                                thumbnail: null,
                                name: "Part 1",
                                description: null,
                                file: "https://gcf-storage.s3.ap-southeast-1.amazonaws.com/staging/learns/life_journeys/pdfs/wEXUiAD4y3VUjFKwGmuNiq3PpL610KUU9ZaKsgDd.pdf",
                                google_slides_url: null,
                                pdf_url: null,
                                pages: 8,
                                hour_to_read: 1,
                                created_at: "2022-09-01 11:39:19",
                                updated_at: "2022-09-01 11:39:19",
                                deleted_at: null,
                            },
                            {
                                id: 77,
                                attachmentable_type:
                                    "App\\Models\\LifeJourneySubject",
                                attachmentable_id: 18,
                                thumbnail: null,
                                name: "Part 2",
                                description: null,
                                file: "https://gcf-storage.s3.ap-southeast-1.amazonaws.com/staging/learns/life_journeys/pdfs/VHN6ivC5uZ7JAiMx7xXppI6pFAPrKoqZvIIhFsvk.pdf",
                                google_slides_url: null,
                                pdf_url: null,
                                pages: 10,
                                hour_to_read: 1,
                                created_at: "2022-09-01 11:39:19",
                                updated_at: "2022-09-01 11:39:19",
                                deleted_at: null,
                            },
                        ],
                    },
                ],
                tags: [],
            },
        };
    },

    methods: {
        changeView(to) {
            this.$router.push(to);
        },

        openBannerFormDialog({ action, data }) {
            console.log(action, data);
            switch (action) {
                case "add":
                    this.bannerForm = { show: true, action, data: null };
                    break;
                case "edit":
                    this.bannerForm = {
                        action,
                        data: Object.assign({}, data),
                    };
                    this.bannerForm.show = true;
                    break;
                case "delete":
                    break;
                default:
                    this.bannerForm = { show: true, action, data: null };
                    break;
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.v-select::v-deep .v-input__control {
    max-height: 20px !important;
}
.v-select::v-deep .v-select__selection--comma {
    color: #78756a;
}
</style>
